import { render, staticRenderFns } from "./ModalChannelsBudgets.vue?vue&type=template&id=32b6be9f&"
import script from "./ModalChannelsBudgets.vue?vue&type=script&lang=js&"
export * from "./ModalChannelsBudgets.vue?vue&type=script&lang=js&"
import style0 from "../../assets/sass/mediaPlanPlacementTypes.sass?vue&type=style&index=0&prod&lang=sass&"
import style1 from "./ModalChannelsBudgets.vue?vue&type=style&index=1&id=32b6be9f&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports